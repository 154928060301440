/* Component Dependencies */
var languageSelectorHWSTemplate = require('templates/languageSelectorHWS.hbs');
var languageSeletorHSIATemplate = require('templates/languageSelectorHSIA.hbs');

var AriesComponent = require('libs/aries-component');
var Popup = require('libs/popup');
var AutoComplete = require('utils/autoCompleteTypeHandler');

AriesComponent.create({
  type: 'languageSelectorHWS',
  template: {
    'languageSelectorHWS': languageSelectorHWSTemplate,
    'languageSelectorHSIA': languageSeletorHSIATemplate
  },
  unBindEvents: function($) {
    $('[data-component-id="' + this.$el.data('component-id') + '"] *').off();
    $('[data-component-id="' + this.$el.data('component-id') + '"]').off();
  },
  bindEvents: function() {
    'use strict';
    // code specific to headerLanguageSelection component here
    if(this.$el.hasClass('mi-popover')) {
      var languageSelection = new Popup({
        selector: '.tile-quick-link-item.mi-popover',
        sourceBlock: '.modal-content',
        afterClose: function() {
         if(window.gigya) {
          window.gigya.__initialized = false;
        }
      }
    });  
      languageSelection.register();
    }

    // code specific to language selector in HSIA connection portal 
    if(this.$el.hasClass('mi-dropdown')) {
      this.$el.on('click', _.bind(this.toggleDropdownDisplay, this));
    }
  },

  /**
  * This function is used to dispatch event to toggle the dropdown
  * on clicking the language selector in HSIA connection portal 
	* @param {Object} event Mouse event triggered on language dropdown click. 
	*/
  toggleDropdownDisplay: function (event) {
    event.preventDefault();
		event.stopPropagation();
    this.pubsub.publish('TOGGLE_LANGUAGE_DROPDOWN');
  }
});
